import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import List from '../components/List'

const resources = ({ location }) => {
  const queryData = useStaticQuery(graphql`
    {
      allContentfulResources(sort: { fields: createdAt, order: DESC }) {
        edges {
          node {
            id
            category
            title
            slug
            excerpt
            image {
              file {
                url
              }
            }
            body {
              json
            }
            createdAt(formatString: "MMMM Do YYYY")
          }
        }
      }
    }
  `)
  const TYPE = 'Resource'
  const IMAGE_HEIGHT = 250
  const data = queryData.allContentfulResources.edges.map(obj => ({
    id: obj.node.id,
    title: obj.node.title,
    body: obj.node.excerpt,
    image: obj.node.image,
    imageHeight: IMAGE_HEIGHT,
    slug: `/resources/${obj.node.slug}`,
    category: obj.node.category,
    type: TYPE
  }))

  return (
    <Layout>
      <SEO title="Resources Admin" />
      <h1>Resources - Admin</h1>
      <div className="container">
        <List data={data} itemType="subMenu" className="items-container" />
      </div>
    </Layout>
  )
}

export default resources

resources.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}
